import { Container, Section } from '@components/common'
import Button from '@mrs-ui/Button'
import Tag from '@mrs-ui/Tag'
import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'

interface Feature {
  clientName: string
  clientLogo: string
  excerpt: string
  link: string
  industry: string
}

const Feature: React.FC<Feature> = ({ ...props }) => {
  return (
    <div>
      <div style={{ height: '164px' }} className="mb-6">
        <img
          src={props.clientLogo}
          className="w-1/2 object-contain max-h-full"
          alt={`Logo for ${props.clientLogo}`}
        />
      </div>
      <div className="">
        <Tag content={props.industry} className="mb-6" />
        <h3>{props.clientName}</h3>
        <p>{props.excerpt}</p>
        <p>
          <Button appearance="link" link={props.link}>
            Read the case study
          </Button>
        </p>
      </div>
    </div>
  )
}

const ClientSuccess = () => {
  const data = useStaticQuery(graphql`
    query {
      td: wpCaseStudy(id: { eq: "cG9zdDo5OQ==" }) {
        id
        slug
        title
        content
        excerpt
        date(formatString: "YYYY")

        acf {
          clientName
          clientLogo {
            sourceUrl
          }
          industry
        }
      }
      alert: wpCaseStudy(id: { eq: "cG9zdDoxMTI=" }) {
        id
        slug
        title
        content
        excerpt
        date(formatString: "YYYY")

        acf {
          clientName
          clientLogo {
            sourceUrl
          }
          industry
        }
      }
      nandos: wpCaseStudy(id: { eq: "cG9zdDoxMDI=" }) {
        id
        slug
        title
        content
        excerpt
        date(formatString: "YYYY")

        acf {
          clientName
          clientLogo {
            sourceUrl
          }
          industry
        }
      }
      hiroc: wpCaseStudy(id: { eq: "cG9zdDozNjYyNw==" }) {
        id
        slug
        title
        content
        excerpt
        date(formatString: "YYYY")

        acf {
          clientName
          clientLogo {
            sourceUrl
          }
          industry
        }
      }
    }
  `)

  return (
    <Section className="bg-shade50">
      <Container>
        <h2 className="md:w-2/3">
          We're helping organizations like yours thrive with Power BI
        </h2>

        <div className="flex flex-wrap -mx-8 mt-gap-large">
          <div className="md:w-1/2 w-full px-8 pb-12">
            <Feature
              industry={data.hiroc.acf.industry}
              link={`/client-success/${data.hiroc.slug}`}
              clientLogo={data.hiroc.acf.clientLogo.sourceUrl}
              clientName={data.hiroc.title}
              excerpt={data.hiroc.excerpt}
            />
          </div>
          <div className="md:w-1/2 w-full px-8 pb-12">
            <Feature
              industry={data.td.acf.industry}
              link={`/client-success/${data.td.slug}`}
              clientLogo={data.td.acf.clientLogo.sourceUrl}
              clientName={data.td.title}
              excerpt={data.td.excerpt}
            />
          </div>
          <div className="md:w-1/2 w-full px-8 pb-12">
            <Feature
              industry={data.alert.acf.industry}
              link={`/client-success/${data.alert.slug}`}
              clientLogo={data.alert.acf.clientLogo.sourceUrl}
              clientName={data.alert.title}
              excerpt={data.alert.excerpt}
            />
          </div>
          <div className="md:w-1/2 w-full px-8 pb-12">
            <Feature
              industry={data.nandos.acf.industry}
              link={`/client-success/${data.nandos.slug}`}
              clientLogo={data.nandos.acf.clientLogo.sourceUrl}
              clientName={data.nandos.title}
              excerpt={data.nandos.excerpt}
            />
          </div>
        </div>
        <div>
          <Button link="/contact" appearance="primary" actionIntent>
            Discuss a solution like this for your business
          </Button>
        </div>
      </Container>
    </Section>
  )
}
export default ClientSuccess
