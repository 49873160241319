import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Container, Section } from '@components/common'
import { Button } from '@mrs-ui'

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "images/stock/stock_powerbi.jpeg" }) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Section
      verticalSpacing="xxl"
      backgroundImage={data.image.childImageSharp.fluid}
      theme="dark"
      overlay
    >
      <Container>
        <div className="md:w-1/2 w-full">
          <h1 className="mb-8">Microsoft Power BI Consulting Services</h1>
          <p className="text-large">
            All your data. All in one place. Supercharged by the most powerful
            cloud-based business intelligence reporting system. MRS can help you
            get there.
          </p>
        </div>
        <div className="flex flex-wrap mt-gap-default items-center">
          <Button className="mr-gap-mini" appearance="primary" link="/contact">
            Let's get to work
          </Button>
        </div>
      </Container>
    </Section>
  )
}
export default Hero
