import React from 'react'
import { Container, Section } from '@components/common'
import ContentFeature from '@components/ContentFeature'
import Icon from '@mrs-ui/Icon'
import { graphql, useStaticQuery } from 'gatsby'
import Button from '@mrs-ui/Button'

const Section1 = () => {
  const data = useStaticQuery(graphql`
    query {
      imageConsulting: file(
        relativePath: { eq: "images/stock/it-software-consulting-toronto-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageDev: file(relativePath: { eq: "images/stock/h004.webp" }) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageManagedServices: file(
        relativePath: { eq: "images/stock/it-software-consulting-toronto-94.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Section verticalSpacing="xl">
      <Container>
        <div className="md:w-3/4">
          <h2>Actionable insights, better business decisions</h2>
          <p>
            Power BI - Microsoft's industry-leading cloud-based analytics platform
            - gives you unparalleled insights and analysis across your enterprise.
            Decision-makers get access to highly interactive and graphical
            business intelligence reports, empowering them to make better business
            decisions, faster, based on insights from real-time data. With Power
            BI, you take the guesswork out of decision-making and let your data be
            your competitive advantage.
          </p>
          <p>
            MRS can be your trusted partner to help you realize this potential.
            With a <b>20+ year mature business intelligence practice</b>, and as a{' '}
            <b>certified Microsoft Gold Partner and Cloud Solutions Provider</b>,
            we have the experience and expertise necessary to deliver end-to-end
            Power BI solutions that delight decision-makers and bring clarity to
            your data.
          </p>
        </div>

        <div className="mt-gap-large -mx-8 flex flex-wrap">
          <div className="lg:w-1/3 md:w-1/2 w-full px-8 pb-6">
            <ContentFeature
              icon={<Icon name="BarChart2" />}
              title="Power BI Report Architecture and Design"
              excerpt="Our data analysts, business intelligence consultants and software engineers can design and architect fully custom Power BI reporting dashboards with interactive data visualizations."
            />
          </div>
          <div className="lg:w-1/3 md:w-1/2 w-full px-8 pb-6">
            <ContentFeature
              icon={<Icon name="Eye" />}
              title="Power BI Consulting"
              excerpt="Thinking about adopting Power BI and not sure where to start? Already a Power BI user but feel you're not utilizing it effectively? Our business intelligence team can help you identify how to get the most out of Power BI and how Power BI can be your competitive advantage."
            />
          </div>
          <div className="lg:w-1/3 md:w-1/2 w-full px-8 pb-6">
            <ContentFeature
              icon={<Icon name="Database" />}
              title="Power BI Data Integration"
              excerpt="Our business intelligence team can help you connect your data - wherever it lies - to Power BI, giving you a central analytics platform that agregates and unifies data from across your enterprise."
            />
          </div>
        </div>
        {/* <div className="mt-6">
          <Button appearance="primary" link="/contact" actionIntent>
            Speak to a SharePoint expert today
          </Button>
        </div> */}
      </Container>
    </Section>
  )
}
export default Section1
