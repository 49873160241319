import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Container, Section } from '@components/common'

import Img from 'gatsby-image'

const Section2 = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(
        relativePath: { eq: "images/stock/it-software-consulting-toronto-89.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "images/stock/h002.jpeg" }) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "images/stock/img111.webp" }) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Section verticalSpacing="xl">
      <Container>
        <h2>What can Power BI do for your business?</h2>
        <div className="flex flex-wrap items-center -mx-10 my-gap-xl">
          <div className="sm:w-1/2 w-full px-10 sm:mb-0 mb-gap-mini">
            <Img
              fluid={data.image1.childImageSharp.fluid}
              style={{ height: '360px' }}
            />
          </div>
          <div className="sm:w-1/2 w-full px-10 mb-gap-mini">
            <h2 className="mb-4">
              Data visualization that brings you from confusion to clarity
            </h2>
            <p>
              With Power BI, you can turn your data into highly interactive,
              intuitive and mobile responsive business reports that brings you
              from confusion to clarity. Drill down into KPI's, harness big data
              analytics and create the reports your decision-makers need to
              deliver unparalleled insights that drive more informed, data-driven
              operations. No longer will you feel unsure of what your data is
              trying to tell you.
            </p>
          </div>
        </div>
        <div className="flex flex-wrap items-center -mx-10 my-gap-xl">
          <div className="sm:w-1/2 w-full px-10 sm:mb-0 mb-gap-mini">
            <Img
              fluid={data.image2.childImageSharp.fluid}
              style={{ height: '360px' }}
            />
          </div>
          <div className="sm:w-1/2 w-full px-10">
            <h2 className="mb-4">
              Data analytics on any platform or business app
            </h2>
            <p>
              With Power BI Desktop, the Power BI mobile app (for smartphone and
              tablet), Power BI Embedded and the Microsoft Power Platform, you can
              deliver insights to decision-makers on any platform you can imagine.
              Integrate Power BI into your SharePoint intranets and team sites,
              build engaging Microsoft Teams pages to fuse real-time analytics
              with real-time communications or deploy completely custom business
              applications with built-in Power BI reports.
            </p>
            <p>
              With Power BI, the possibilities to create and deploy user-friendly
              analytics reports are endless - helping you form and foster your
              data-driven culture.
            </p>
          </div>
        </div>
        <div className="flex flex-wrap items-center -mx-10 my-gap-xl">
          <div className="sm:w-1/2 w-full px-10 sm:mb-0 mb-gap-mini">
            <Img
              fluid={data.image3.childImageSharp.fluid}
              style={{ height: '360px' }}
            />
          </div>
          <div className="sm:w-1/2 w-full px-10">
            <h2 className="mb-4">
              One analytics service for all your data - wherever it lies
            </h2>
            <p>
              Wherever your data lies, you can connect it to Power BI. Aggregate
              data from over 200 sources to streamline information across your ERP
              systems and connect the dots to gain insights across your
              enterprise. Connect to data from on-premise SQL Server databases,
              Azure, Dynamics CRM, Microsoft Dynamics GP ERP,
              SharePoint/SharePoint Online, AWS, SAP, Google Cloud and countless
              more.
            </p>
          </div>
        </div>
      </Container>
    </Section>
  )
}
export default Section2
