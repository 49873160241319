import React from 'react'

import Head from '@components/Head'
import {
  Hero,
  Logos,
  Section1,
  Section2,
  ClientSuccess,
  CTA,
} from '@components/pages/lp/power-bi'
import MoreLinksSection from '@components/pages/lp/MoreLinksSection'

const PowerBIServicesLp = () => {
  return (
    <>
      <Head
        title="Power BI Services Toronto"
        description="Microsoft Power BI services for business in Toronto. Data visualizations, data analytics, dashboards and reports on Power BI. Power BI Embedded analytics."
      />
      <Hero />
      <Logos />
      <Section1 />
      <ClientSuccess />
      <Section2 />
      <CTA />
      <MoreLinksSection />
    </>
  )
}
export default PowerBIServicesLp
